@import '~bootstrap/scss/bootstrap'
@import '~owl.carousel/dist/assets/owl.carousel.css'
@import '~owl.carousel/dist/assets/owl.theme.default.css'
@import '~lightbox2/dist/css/lightbox.css'
@import '~jquery-form-validator/src/theme-default.css'
@import '~animate.css/animate.css'

$fa-font-path: "../fonts"
@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss'
@import '~@fortawesome/fontawesome-pro/scss/brands.scss'
@import '~@fortawesome/fontawesome-pro/scss/light.scss'
@import '~@fortawesome/fontawesome-pro/scss/regular.scss'
@import '~@fortawesome/fontawesome-pro/scss/solid.scss' 
